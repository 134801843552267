/* globals document */
/* eslint-disable no-param-reassign */
const defaultStream = {
	is: x => typeof x == 'function' && x.map, // lol
	getVal: x => x(),
	hasVal: x => typeof x() != 'undefined',
	subscribe(f, x) {
		return x.map(f)
	},
	unsubscribe(x) {
		x.end(true)
	},
}

function CSS({
	sheets = {},
	Stream = defaultStream,
	env = typeof window == 'undefined'
		? ServerEnv({ Stream })
		: BrowserEnv({ Stream }),
	hash = defaultHashCode,
} = {}) {
	let renders = 0

	function hashSheet(sheet) {
		if (sheet in sheets) {
			return sheets[sheet]
		} else {
			return (sheets[sheet] = hash(sheet))
		}
	}

	const Value = {
		Var(value) {
			return { type: 'Value', tag: 'Var', value }
		},
		Animation(reference) {
			return { type: 'Value', tag: 'Animation', value: reference }
		},
		AnimationReference(reference) {
			return {
				type: 'Value',
				tag: 'AnimationReference',
				value: reference,
			}
		},
		Static(value) {
			return { type: 'Value', tag: 'Static', value }
		},
		Chain({ pairs }) {
			return { type: 'Value', tag: 'Chain', value: { pairs, chained: true } }
		},
		equals(a, b) {
			return JSON.stringify(a) == JSON.stringify(b)
		},
		fold({ Static, Var, Chain, Animation, AnimationReference }) {
			return x =>
				({
					Static,
					Var,
					Chain,
					Animation,
					AnimationReference,
				}[x.tag](x.value))
		},
	}

	const Pair = {
		infer([k, v]) {
			if (v == null) {
				// ??? not sure
				return [k, Value.Static('')]
			}

			if (typeof v == 'number') {
				v = v + ''
			}

			return typeof v == 'string'
				? v.includes('@') || k.includes('@')
					? [k, Value.Static(v)]
					: v.includes(':') || v.includes(';')
					? [k, Value.Chain(Sheet()([v]))]
					: [k, Value.Var(v)]
				: typeof x == 'number'
				? [k, Value.Var(v)]
				: v.tag == 'CSSChainAPI'
				? [k, Value.Chain({ pairs: v.pairs })]
				: Stream.is(v)
				? [k, Value.Var(v)]
				: [k, v]
		},
	}

	function getSheet() {
		return env.getSheet()
	}

	function Sheet({ pairs = [], chained = false } = {}) {
		let renderId = ++renders

		const originalPairs = pairs

		function entry(theirStrings, ...theirValues) {
			// const oldSections = sections.slice()

			let stack = originalPairs.concat(
				theirStrings.map((x, i) => Pair.infer([x, theirValues[i]])),
			)

			let pairs = []
			let placeholder = Value.Static('')

			while (stack.length) {
				let [string, value] = stack.shift()

				// Is the Value a Chain? if so, we'll put the values
				// strings/values next on the stack

				let pair = [string, placeholder]

				if (value) {
					if (value.tag == 'Chain') {
						stack.unshift(...value.value.pairs)
					} else {
						pair[1] = value
					}
				}

				pairs.push(pair)
			}

			let filteredPairs = pairs.filter(
				x =>
					!(x[0].replace(/\s+/g, '') == '' && Value.equals(x[1], placeholder)),
			)

			const out = new Sheet({
				pairs: filteredPairs,
				chained: true,
			})

			return out
		}

		function $nest(theirSelector, definition) {
			const selector = theirSelector.startsWith('&')
				? theirSelector
				: `\n& ${theirSelector} `

			return entry([`${selector} {\n`, `\n}\n`], definition)
		}

		function $media(query, definition) {
			return entry`
				@media ${Value.Static(query)} {
					${definition}
				}
			`
		}

		function $animate(timing, definition) {
			const reference =
				typeof definition == 'string'
					? hash(definition)
					: 'getSheet' in definition
					? hash(definition.getSheet())
					: hash(
							JSON.stringify(
								Object.fromEntries(
									Object.entries(definition).map(([k, v]) => [
										k,
										v.getSheet ? v.getSheet() : v,
									]),
								),
							),
					  )

			const definitionInstance =
				typeof definition == 'string'
					? definition
					: 'getSheet' in definition
					? definition
					: Value.Chain({
							pairs: Object.entries(definition).flatMap(([key, value]) => {
								const pairs = [
									['\n' + key + ' {\n', value],
									['\n}', null],
								].map(Pair.infer)

								return pairs
							}),
					  })

			return entry`
				@keyframes ${Value.Animation(reference)} {
					${definitionInstance}
				}

				&& {
					animation: ${Value.AnimationReference(reference)} ${Value.Static(timing)};
				}
			`
		}

		function processComments(line) {
			let i = line.indexOf('//')
			if (i > -1) {
				if (line[i - 1] == ':') {
					// not a comment, but a protocol
					return line
				}
				return line.slice(0, i) + line.slice(i).replace('//', '/*') + ' */'
			}
			return line
		}

		// eslint-disable-next-line complexity
		function getSheet() {
			let vars = []
			let animations = []

			let stack = pairs.slice()

			let sheet = ''

			let isAtRule = false
			let isBlock = false
			let isComment = false
			let opens = 0
			let closes = 0
			let braceInserted = false

			let animationReferences = {}

			let indent = s =>
				Array(Math.max(0, opens - closes + (s.includes('{') ? -1 : 0)))
					.fill('  ')
					.join('') + s

			let indented = s => indent(s.replace(/^\s+/, ''))

			while (stack.length) {
				const [k, unformattedV] = stack.shift()

				let formattedV = Value.fold({
					Var: () => {
						vars.push(unformattedV)
						return `var(--var_&_${vars.length - 1})`
					},
					Animation: reference => {
						animations.push(unformattedV)
						animationReferences[reference] = `animation_&_${animations.length}`
						return animationReferences[reference]
					},
					AnimationReference: reference => {
						return animationReferences[reference]
					},
					Static: x => {
						return x
					},
					Chain() {
						throw new Error(
							'Value.Chain should always be flattened before formatting.',
						)
					},
				})(unformattedV)

				let lines = []
				let re_braces = /\{|\}|\(|\)/g

				const lineStack = Object.entries(k.split('\n'))

				if (unformattedV.tag == 'Static') {
					lineStack[lineStack.length - 1][1] += formattedV
					formattedV = ''
				}

				for (let [i, line] of lineStack) {
					line = processComments(line)

					if (line.includes('/*')) {
						if (!line.includes('*/')) {
							isComment = true
						}
						lines.push(indented(line))
						continue
					} else if (line.includes('*/')) {
						isComment = false
						lines.push(indented(line))
						continue
					} else if (isComment) {
						lines.push(indented(line))
						continue
					}

					let originalOpens = opens
					let originalCloses = closes
					opens += (line.match(/\{/g) || []).length
					closes += (line.match(/\}/g) || []).length

					let opensDelta = opens - closes

					let lineWithoutBracingAndSpacing = [line.indexOf('/*')]
						.map(i => (i == -1 ? line.length : i))
						.map(i => line.slice(0, i))
						.map(s => s.replace(re_braces, '').replace(/\s+/g, ''))[0]

					let lineHasRules = !isComment && lineWithoutBracingAndSpacing != ''

					if (line.replace(/\s+/g, '') == '') {
						lines.push(indented(''))
						continue
					}

					if (opensDelta < 0) {
						opens = originalOpens
						closes = originalCloses
						continue
					}

					if (line.includes('@')) {
						if (!isAtRule && isBlock) {
							closes += 1
							lines.push(indented('}'), '')
							isBlock = false
							braceInserted = false
						} else if (isBlock) {
							closes += 1
							lines.push(indented('}'), '')
							closes += 1
							lines.push(indented('}'), '')
							isBlock = false
							braceInserted = false
						} else if (isAtRule) {
							closes += 1
							lines.push(indented('}'))
						}

						isAtRule = true

						if (opensDelta > 1) {
							isBlock = true
							braceInserted = false
						}
						lines.push(indented(line))
					} else if (isAtRule && opensDelta < 1) {
						isAtRule = false
						isBlock = false
						lines.push(indented(line))
						// detect block inside at rule closing
					} else if (isAtRule && isBlock && opensDelta < 2) {
						if (braceInserted) {
							isAtRule = false
							lines.push(indented('}'))
							closes += 1 // back to the future
							braceInserted = false
						}
						isBlock = false
						lines.push(indented(line))

						// detect at rule or block closing
					} else if (isBlock && opensDelta < 1) {
						isAtRule = false
						isBlock = false
						braceInserted = false
						lines.push(indented(line))

						// naked block closing when new block starts
					} else if (
						(isAtRule && opensDelta > 2) ||
						(!isAtRule && isBlock && opensDelta > 1)
					) {
						closes += 2
						lines.push(indented('}'), '')
						braceInserted = false
						closes -= 1
						lines.push(indented(line))
						// detect block opening in source
					} else if (isAtRule && !isBlock && opensDelta > 1) {
						braceInserted = false
						isBlock = true
						lines.push(indented(line))
					} else if (!isAtRule && !isBlock && opensDelta > 0) {
						braceInserted = false
						isBlock = true
						lines.push(indented(line))
						// detect block required
					} else if (!isBlock && opensDelta == 0 && lineHasRules) {
						opens++
						lines.push(indented('&& {'))
						braceInserted = true
						isBlock = true
						lines.push(indented(line))

						// detect block required in at-rule
					} else if (isAtRule && !isBlock && opensDelta == 1 && lineHasRules) {
						opens++
						lines.push(indented('&& {'))
						isBlock = true
						braceInserted = true
						lines.push(indented(line))

						// normal
					} else {
						let continuingLine = sheet.length > 0 && i == 0

						lines.push(continuingLine ? line : indented(line))
					}
				}

				sheet += lines.join('\n')
				sheet += formattedV
			}

			if (opens - closes != 0) {
				closes++
				sheet += '\n}'
			}

			return [sheet]
				.join('\n')
				.split('\n')
				.filter(s => !s.replace(/\s+/g, '') == '')
				.join('\n')
		}

		function _class() {
			const sheet = getSheet()
			const classname = 'css_' + hashSheet(sheet)

			const replacedSheet = sheet
				.replace(/_\&/g, '_' + classname)
				.replace(/\&/g, '.' + classname)

			const renderSelector = `css-render-${renderId}`

			env.insertSheet(classname, renderSelector, replacedSheet, pairs)
			env.updateVars(classname, renderSelector, replacedSheet, pairs)

			return [classname, renderSelector]
		}

		const api = {
			tag: 'CSSChainAPI',
			pairs,
			getSheet,
			$nest,
			$media,
			$animate,
			_class,
			get class() {
				return _class().join(' ')
			},
			valueOf() {
				return '.' + _class().join('.')
			},
		}

		return chained ? api : entry
	}

	const css = Object.assign(Sheet(), Sheet()``)

	return Object.assign(css, {
		env,
		Value,
		Sheet,
		hash,
		getSheet,
		trust: Value.Static,
	})
}

// Inspired by: https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
function defaultHashCode(s) {
	let hash = 0
	for (let x of s) {
		let char = x.charCodeAt()
		hash = (hash << 5) - hash + char
		hash &= hash
	}
	hash = hash >>> 0
	return hash.toString(15)
}

function ServerEnv({ Stream = defaultStream } = {}) {
	const sheets = {}
	const varDeclarations = {}
	function insertSheet(hash, renderSelector, sheet) {
		sheets[hash] = sheet

		return sheet
	}

	const getVarVal = x => (Stream.is(x) ? Stream.getVal(x.value) : x.value)

	function updateVars(hash, renderSelector, sheet, pairs) {
		const vars = pairs.flatMap(([_, x]) => (x.tag == 'Var' ? [x] : []))

		const varDeclaration = vars.map(
			(x, i) => `--var_${hash}_${i}: ${getVarVal(x)};`,
		)

		varDeclarations[hash] = varDeclaration.length
			? [
					`.${hash}.${renderSelector} {`,
					varDeclaration.map(x => `  ${x}`).join('\n'),
					`}`,
			  ]
			: []
	}

	function getSheet() {
		return []
			.concat(
				Object.values(sheets).join('\n'),
				Object.values(varDeclarations).map(x => x.join('\n')),
			)
			.join('\n')
	}

	return {
		insertSheet,
		updateVars,
		getSheet,
	}
}

function BrowserEnv({
	Stream = defaultStream,

	// eslint-disable-next-line no-undef
	doc = document,
	// eslint-disable-next-line no-undef
	timeout = setTimeout,
	// eslint-disable-next-line no-undef
	raf = requestAnimationFrame,
}) {
	const inserted = {}

	const styleEl = document.createElement('style')
	document.head.appendChild(styleEl)

	function insertSheet(hash, _, sheet) {
		if (!(hash in inserted)) {
			inserted[hash] = sheet
			styleEl.textContent += sheet
		}
		return sheet
	}

	function setProperty(el, varName, value) {
		el.style.setProperty(varName, value)
	}

	// Detect if a hash+stream has been subscribed before
	// The same hash will be the class name for all elements
	// that need that var updated to that stream value
	// { [stream]: [(Element/Selector,VarName)] }
	let streamVarUpdates = new WeakMap()

	function streamSetProperty(el, varName, stream) {
		let subscribe = false
		if (!streamVarUpdates.has(stream)) {
			let map = new Map()
			streamVarUpdates.set(stream, map)

			subscribe = true
		}

		const map = streamVarUpdates.get(stream)

		if (!map.has(el)) {
			map.set(el, new Set())
		}

		const set = map.get(el)
		set.add(varName)
		if (!subscribe && Stream.hasVal(stream)) {
			setProperty(el, varName, Stream.getVal(stream))
		}

		if (subscribe) {
			let ref = Stream.subscribe(value => {
				const map = streamVarUpdates.get(stream)
				for (let [el, varNames] of map) {
					if (el.parentNode) {
						for (let varName of varNames) {
							setProperty(el, varName, value)
						}
					} else {
						// unsubscribe el
						map.delete(el)
						// unsubscribe stream
						if (map.size() == 0) {
							streamVarUpdates.delete(stream)
							Stream.unsubscribe(ref)
						}
					}
				}
			}, stream)
		}
	}

	const scheduleF = f => (selector, varName, value, { remaining = 3 } = {}) => {
		if (remaining > 0) {
			raf(() => {
				let els = doc.getElementsByClassName(selector)

				if (!els.length) {
					timeout(() => {
						scheduleF(f)(selector, varName, value, {
							remaining: remaining - 1,
						})
					}, 100)
				} else {
					for (let el of els) {
						f(el, varName, value)
					}
				}
			})
		}
	}

	const schedule = scheduleF(setProperty)
	const scheduleStream = scheduleF(streamSetProperty)

	function updateVars(hash, renderSelector, __, pairs) {
		const vars = pairs.flatMap(([_, x]) => (x.tag == 'Var' ? [x] : []))

		vars.forEach((x, i) => {
			if (Stream.is(x.value)) {
				scheduleStream(renderSelector, `--var_${hash}_${i}`, x.value)
			} else {
				schedule(renderSelector, `--var_${hash}_${i}`, x.value)
			}
		})
	}

	function getSheet() {
		return Object.values(inserted).join('\n')
	}
	return {
		insertSheet,
		updateVars,
		getSheet,
	}
}

export default CSS()
